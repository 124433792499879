@charset "utf-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  border: 0;
  outline: 0;
  background: transparent; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  text-decoration: none;
  color: #000;
  background-color: #ff9; }

/* change colours to suit your needs */
mark {
  font-weight: bold;
  font-style: italic;
  color: #000;
  background-color: #ff9; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  cursor: help;
  border-bottom: 1px dotted; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #cecece; }

input,
select {
  vertical-align: middle; }

ul,
ol {
  list-style-type: none; }

input[type='submit'],
input[type='button'] {
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  border-radius: 0;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button; }

input[type='submit']::-webkit-search-decoration,
input[type='button']::-webkit-search-decoration {
  display: none; }

input[type='submit']::focus,
input[type='button']::focus {
  outline-offset: -2px; }

*, *:before, *:after{
  box-sizing:border-box;
}

//reset

//ベースフォントサイズ
$baseFontSize: 16;
//スマホPSDの横幅
$SmartphoneSize: 750;

//ベース
$widthBase: 1120px;

//メインコンテンツ
$widthMain: 840px;

//サイドナビ
$widthSide: 250px;

//ベースフォント
$fontBase: "ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic Pro," メイリオ", Meiryo, Osaka," ＭＳ Ｐゴシック", MS PGothic," sans-serif";

//サブフォント
$subFont: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

//rem() ※px指定
@function rem($pixels,$context:$baseFontSize)
{
  @return $pixels / $context * 1rem;
}

//vw()
@function vw($size) {
  @return (($size / $SmartphoneSize) * 100) + vw;
}

//@include liBottom();
@mixin liBottom($px:10,$borderColor:#000)
{
  border-bottom: 1px solid $borderColor;
  margin-bottom: $px + px;
  padding-bottom: $px + px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw($px * 1.5);
    padding-bottom: vw($px * 1.5);
  }
}

//@include link-color();
@mixin link-color($color:blue) {
  color: $color;

  &:hover, &:active, &:focus {
      color: lighten($color, 20%);
    }
}

@mixin gfont() {
	font-family: 'DotGothic16', sans-serif;
}

@mixin bg() {
  @media only screen and (max-width:1120px){
    width:1120px;
    @media only screen and (max-width:$SmartphoneSize + px){
      width:100%;
    }
  }
}

@function basecolor() {
  @return #1a5ed9;
}

@mixin base_h2() {
  font-size:rem(30);
  padding:15px;
  margin-bottom:40px;
  @media only screen and (max-width:$SmartphoneSize + px){
    font-size:vw(40);
    padding:vw(18);
    margin-bottom:vw(40);
  }
  &:before{
    content:'';
    width:74px;
    height:39px;
    margin-right:20px;
    @media only screen and (max-width:$SmartphoneSize + px){
      width: vw(74);
      height:vw(39);
      margin-right:vw(20);
    }
    background:url(../images/icon_h2.svg) no-repeat left top;
    background-size: cover;
  }
  @include gfont();
  color:#fff;
  display: flex;
  justify-content: flex-start;
  flex-wrap:wrap;
  align-items: center;
  background:basecolor();
}





//setting ----------------------------------------------------

html {
  font-size: $baseFontSize + px;
  @media only screen and (max-width:$SmartphoneSize + px){
    font-size: vw($baseFontSize * 1.8);
  }
}

body {
  line-height: 1.7;
  margin: 0;
  padding: 0;
  font-family: $fontBase;
}

img {
  border: none;
}

.clear {
  clear: both;
  height: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

ul, ol {
  list-style: none;
}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  text-align: center;
}

.tleft {
  text-align: left;
}

.tright {
  text-align: right;
}

.pc {
  display: block;
  @media only screen and (max-width:$SmartphoneSize + px){
    display: none;
  }
}
.sp {
  display: none;
  @media only screen and (max-width:$SmartphoneSize + px){
    display: block;
  }
}
.mt5 {
  margin-top: 5px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(5);
  }
}
.mb5 {
  margin-bottom: 5px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(5);
  }
}
.ml5 {
  margin-left: 5px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(5);
  }
}
.mr5 {
  margin-right: 5px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(5);
  }
}
.mt10 {
  margin-top: 10px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(10);
  }
}
.mb10 {
  margin-bottom: 10px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(10);
  }
}
.ml10 {
  margin-left: 10px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(10);
  }
}
.mr10 {
  margin-right: 10px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(10);
  }
}
.mt15 {
  margin-top: 15px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(15);
  }
}
.mb15 {
  margin-bottom: 15px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(15);
  }
}
.ml15 {
  margin-left: 15px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(15);
  }
}
.mr15 {
  margin-right: 15px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(15);
  }
}
.mt20 {
  margin-top: 20px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(20);
  }
}
.mb20 {
  margin-bottom: 20px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(20);
  }
}
.ml20 {
  margin-left: 20px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(20);
  }
}
.mr20 {
  margin-right: 20px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(20);
  }
}
.mt30 {
  margin-top: 30px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(30);
  }
}
.mb30 {
  margin-bottom: 30px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(30);
  }
}
.ml30 {
  margin-left: 30px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(30);
  }
}
.mr30 {
  margin-right: 30px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(30);
  }
}
.mt40 {
  margin-top: 40px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top: vw(40);
  }
}
.mb40 {
  margin-bottom: 40px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(40);
  }
}
.ml40 {
  margin-left: 40px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-left: vw(40);
  }
}
.mr40 {
  margin-right: 40px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-right: vw(40);
  }
}
.mb50 {
  margin-bottom: 50px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw(50);
  }
}
a:hover img {
  opacity: 0.6;
  filter: alpha(opacity=60);
  -ms-filter: "alpha( opacity=60 )";
  background: none !important;
}

a{
  @include link-color();
}

.inner{
	width:$widthBase;
	margin:0 auto;
	@media only screen and (max-width:$SmartphoneSize + px){
		width:100%;
    padding:0 vw(18);
	}
}

@media only screen and (max-width:$SmartphoneSize + px){
	img{
		width:100%;
		height:auto;
	}
}


header{
	background:basecolor();
	@include bg();
  width:100%;
	display: flex;
	justify-content: space-between;
	flex-wrap:wrap;
	align-items: center;
  z-index:1000;
  &.fix{
    position:fixed;
  }
	#logo{
		padding:20px 0 20px 30px;
		@media only screen and (max-width:$SmartphoneSize + px){
			padding:vw(18);
			img{
				width:vw(314);
			}
		}
	}
	nav{
		ul{
			display: flex;
			justify-content: flex-start;
			flex-wrap:wrap;
			align-items: center;
			li{
				a{
					background:basecolor();
					&:hover{
						background:#fe5f51;
						color:#fff;
					}
					color:#fff;
					text-decoration:none;
					display: flex;
					justify-content: center;
					flex-wrap:wrap;
					align-items: center;
					width:160px;
					height:100px;
					border-left: 1px solid #fff;
					p{
						text-align:center;
						span{
							font-size:rem(20);
							@include gfont();
							display:block;
							width:100%;
						}
					}
				}
			&.contact{
				a{
					background:#fe5f51;
					&:hover{
						background:basecolor();
					}
				}
			}
			}
		}
		@media only screen and (max-width:$SmartphoneSize + px){
			width:calc(100% - #{vw(116)});
			position:fixed;
			left:-100%;
			top:0;
			transition: .5s;
			z-index: 99;
			height:100vh;
			background:basecolor();
			ul{
				li{
					width:100%;
					a{
						padding:vw(50) 0;
						border-bottom:1px solid #fff;
						text-align:center;
						display:block;
						border-left:none;
						width:100%;
						height:auto;
						p{
							width:auto;
							display: inline-flex;
							justify-content: center;
							flex-wrap:wrap;
							align-items: center;
							line-height:1;
							img{
								height:vw(40);
								width:auto;
								margin-right:vw(10);
								&.mb5{
									margin-bottom: 0;
								}
							}
							span{
								width:auto;
								display:inline-block;
								font-size:vw(40);
							}
						}
					}
				}
			}
			&.on{
				left:0;
			}
		}
	}
	#menu{
		display:none;
		@media only screen and (max-width:$SmartphoneSize + px){
			display:block;
			position:relative;
			z-index:100;
			width:vw(116);
			height:vw(116);
			display: flex;
			justify-content: center;
			flex-wrap:wrap;
			align-items: center;
			img{
				width:vw(52);
				height:vw(44);
			}
		}
	}
}

#mainvisual{
  position:relative;
  @include bg();
  .bg{
    width:100%;
    height:500px;
    @media only screen and (max-width:$SmartphoneSize + px){
      height:vw(850);
    }
    object-fit:cover;
  }
  .inner{
    height:500px;
    padding:25px 0;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding:vw(36) vw(18);
      height:auto;
    }
    position:absolute;
    top: 0;
    left:0;
    right:0;
    margin:auto;
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: stretch;
    .box_left{
      padding:15px 50px;
      margin-right: 30px;
      font-size:rem(30);
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size:vw(32);
        width:100%;
        margin-right:0;
        padding:vw(20) vw(30);
        margin-bottom:vw(32);
      }
      color:#fff;
      @include gfont();
      ul{
        margin-bottom:10px;
        padding-bottom:10px;
        @media only screen and (max-width:$SmartphoneSize + px){
          margin-bottom:vw(20);
          padding-bottom:vw(20);
        }
        border-bottom:1px dotted #fff;
        @media only screen and (max-width:$SmartphoneSize + px){
          display: flex;
          justify-content: space-between;
          flex-wrap:wrap;
          align-items: flex-start;
        }
        li{
          position:relative;
          @media only screen and (max-width:$SmartphoneSize + px){
            width:50%;
            padding-left: vw(30);
            &:last-child{
              width:100%;
            }
          }
          &.on{
            &:before{
              content:'';
              display:block;
              width:21px;
              height:17px;
              left:-30px;
              top: 0;
              bottom:0;
              margin:auto;
              @media only screen and (max-width:$SmartphoneSize + px){
                width:vw(21);
                height:vw(17);
                left:vw(5);
              }              
              background:url(../images/arr.png) no-repeat left top;
              background-size: cover;
              position:absolute;

            }
          }
        }
      }
      background:#000;
      border:5px solid #fff;
      border-radius:5px;
    }
    @media only screen and (max-width:$SmartphoneSize + px){
      .visual{
        padding:0 vw(36);
        img{
          width:100%;
        }
      }
    }
  }
}

#reason{
  padding:40px 0;
  h2{
    font-size:rem(30);
    margin-bottom:20px;
    span{
      margin:0 20px;
    }
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
  }
  background:url(../images/reason.png) no-repeat left top #000;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding:vw(60) 0;
    background:#000;
    h2{
      font-size:vw(40);
      margin-bottom:vw(36);
      span{
        margin:0 v(36);
      }
      img{
        width:vw(30);
      }
    }
  }
  @include gfont();
  color:#fff;
}

#hero{
  position:relative;
  @include bg();
  img{
    width:100%;
    height:250px;
    @media only screen and (max-width:$SmartphoneSize + px){
      height:vw(400);
    }
    object-fit:cover;
  }
  .inner{
    position:absolute;
    margin:auto;
    top: 0;
    left:0;
    right:0;
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    height:250px;
    h1,p{
      font-size:rem(44);
      padding:20px;
      border: 5px solid #fff;
      border-radius: 5px;
      background:#000;
      color:#fff;
      line-height:1;
      @include gfont();
    }
    @media only screen and (max-width:$SmartphoneSize + px){
      height:vw(400);
      h1,p{
        font-size:vw(44);
        padding:vw(20);
        line-height:1.4;
      }
    }
  }
}

#page{
  padding:40px 0;

  h2{
    @include base_h2();
  }

  h3{
    font-size:rem(30);
    padding:10px 15px;
    margin-bottom:20px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size:vw(40);
      padding:vw(18);
      margin-bottom:vw(20); 
    }
    background:#999;
    color:#fff;
    @include gfont();
  }


}

#repair_page{
  padding:40px 0;

  h2{
    @include base_h2();
    &.child{
      background:#999;
      &:before{
        display:none;
      }
    }
  }

  h3{
    color:basecolor();
    border-bottom: 3px solid basecolor();
    font-size:rem(26);
    padding-bottom:10px;
    margin-bottom:30px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size:vw(36);
      padding-bottom:vw(20);
      margin-bottom:vw(30);
    }
  }

  #anchor_list{
    display: flex;
    justify-content: flex-start;
    flex-wrap:wrap;
    align-items: center;
    margin-bottom:20px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom:vw(20);
    }
    li{
      width:265px;
      margin-bottom:10px;
      margin-right: 20px;
      &:nth-child(4n){
        margin-right:0;
      }
      @media only screen and (max-width:$SmartphoneSize + px){
        width:49%;
        margin-right:0;
        margin-bottom:vw(18);
      }
      a{
        display:block;
        text-align:center;
        background:#000;
        color:#fff;
        text-decoration:none;
        border-radius:30px;
        padding:5px 0;
        position:relative;
        &:hover{
          background:#f9ac28;
          color:#000;
        }
      }
    }
  }

  .box{
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: flex-start;
    .eye{
      width:360px;
      @media only screen and (max-width:$SmartphoneSize + px){
        width:100%;
        text-align:center;
      }
      img{
        width:360px;
        height:270px;
        object-fit:cover;
        @media only screen and (max-width:$SmartphoneSize + px){
          width:vw(714);
          height:vw(536);
        }
      }
    }
    .content{
      width:calc(100% - 380px);
      @media only screen and (max-width:$SmartphoneSize + px){
        width:100%;
      }
      p{
        margin-bottom:20px;
        @media only screen and (max-width:$SmartphoneSize + px){
          margin-bottom:vw(20);
        }
        &:last-child{
          margin-bottom:0;
        }
      }
      dl{
        border:1px solid #ccc;
        margin-bottom:30px;
        @media only screen and (max-width:$SmartphoneSize + px){
          margin-bottom:vw(30);
        }
        div{
          display: flex;
          flex-wrap:wrap;
          border-bottom:1px solid #ccc;
          &:last-child{
            border-bottom:none;
          }
          dt{
            width:240px;
            padding:30px;
            font-size:rem(20);
            background:#ececec;
            border-right:1px solid #ccc;
            @media only screen and (max-width:$SmartphoneSize + px){
              width:100%;
              text-align:center;
              padding:vw(30);
              font-size:vw(30);
            }
          }
          dd{
            width:calc(100% - 240px);
            padding:30px;
            font-size:rem(20);
            span{
              font-size:rem(14);
            }
            @media only screen and (max-width:$SmartphoneSize + px){
              width:100%;
              padding:vw(30);
              font-size:vw(30);
              text-align:center;
              span{
                font-size:vw(24);
              }
            }
            color:#fe5f51;
            font-weight:bold;
          }
        }
        &.shop{
          div{
            dt,dd{
              padding:10px 30px;
              font-size:rem(16);
              color:#000;
              font-weight:normal;
              @media only screen and (max-width:$SmartphoneSize + px){
                padding:vw(10) vw(30);
                font-size:vw(30);
              }
            }
          }
        }
      }
    }
    .discription{
      width:100%;
      p{
        margin-bottom:10px;
        @media only screen and (max-width:$SmartphoneSize + px){
          margin-bottom:vw(20);
        }
        &:last-child{
          margin-bottom:0;
        }
      }
    }
    iframe{
      width:100%;
      height:360px;
      margin-bottom:30px;
      margin-top:20px;
      @media only screen and (max-width:$SmartphoneSize + px){
        height:vw(360);
        margin-bottom:vw(30);
        margin-top:vw(30);
      }
    }
  }
}

.shop_menu{
  padding:40px 0;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding:vw(50) 0;
  }
  h2{
    @include base_h2(); 
  }
  h3{
    cursor:pointer;
    color:basecolor();
    border-bottom: 3px solid basecolor();
    font-size:rem(26);
    padding-bottom:10px;
    margin-bottom:30px;
    transition: .5s;
    svg{
      width:20px;
      fill:basecolor();
    }
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size:vw(36);
      padding:vw(36);
      margin-bottom:vw(20);
      background:#000;
      border-bottom:3px solid #fff;
      color:#fff;
      svg{
        width:vw(22);
        fill:#fff;
      }
    }
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: center;
    &.on{
      @media only screen and (max-width:$SmartphoneSize + px){
        color:basecolor();
        background:#fff;
        border-bottom:3px solid basecolor();
      }
      svg{
        fill:#f9ac28;
        transform: rotate(90deg);
      }
    }
  }
  .list_wrap{
    display:none;
    h4{
      font-size:rem(28);
      margin-bottom:20px;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size:vw(32);
        margin-bottom:vw(20);
      }
      color:basecolor();
      font-weight:bold;
    }
    dl{
      border:1px solid #ccc;
      margin-bottom:30px;
      @media only screen and (max-width:$SmartphoneSize + px){
        margin-bottom:vw(30);
      }
      div{
        display: flex;
        flex-wrap:wrap;
        border-bottom:1px solid #ccc;
        &:last-child{
          border-bottom:none;
        }
        dt{
          width:30%;
          background:#ececec;
          border-right:1px solid #ccc;
        }
        dd{
          width:70%;
          color:#fe5f51;
          font-weight:bold;
        }

        dt,dd{
          padding:10px 30px;
          font-size:rem(16);
          @media only screen and (max-width:$SmartphoneSize + px){
            padding:vw(10) vw(30);
            font-size:vw(30);
          }
        }

      }
    }
  }
}





.anchor{
  display:block;
  margin-top:-200px;
  padding-top:200px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-top:vw(-120);
    padding-top:vw(120);
  }
}

.repair_menu{
  display: flex;
  justify-content: flex-start;
  flex-wrap:wrap;
  align-items: stretch;
  @media only screen and (max-width:$SmartphoneSize + px){
    justify-content: space-between;
  }
  li{
    width:265px;
    margin-right:20px;
    margin-bottom:20px;
    border:1px solid #ccc;
    &:nth-child(4n){
      margin-right: 0;
    }
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-right:0;
      width:vw(339);
      margin-bottom:vw(36);
    }
    a{
      padding:20px;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding:vw(50) 0;
      }
      text-align:center;
      display:block;
      color:#000;
      border-radius:5px;
      text-decoration:none;
      .eye{
        margin-bottom:10px;
        @media only screen and (max-width:$SmartphoneSize + px){
          margin-bottom:vw(10);
        }
        width:100%;
        overflow:hidden;
        img{
          height:136px;
          @media only screen and (max-width:$SmartphoneSize + px){
            height:vw(170);
          }
          width:auto;
          object-fit:cover;
        }
      }
    }
  }
}

#performance{
  padding:40px 0;
  background:url(../images/bg01.png) repeat-x left top #97d301;
  @include bg();
  @media only screen and (max-width:$SmartphoneSize + px){
    padding:vw(50) vw(18);
    background-size: cover;
  }
  .inner{
    padding:40px 70px;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding:vw(50) vw(40);
    }
    @include gfont();
    color:#fff;
    background:#000;
    border:5px solid #fff;
    border-radius:5px;
    h2{
      font-size:rem(30);
      margin-bottom:30px;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size:vw(40);
        margin-bottom:vw(30);
      }
      text-align:center;
    }
    p{
      margin-bottom:20px;
      @media only screen and (max-width:$SmartphoneSize + px){
        margin-bottom:vw(30);
      }
      &:last-child{
        margin-bottom:0;
      }
    }
    .p_list{
      margin-bottom:20px;
      @media only screen and (max-width:$SmartphoneSize + px){
        margin-bottom:vw(30);
      }
      li{
        margin-bottom:10px;
        @media only screen and (max-width:$SmartphoneSize + px){
          margin-bottom:vw(20);
        }
        &:last-child{
          margin-bottom:0;
        }
        a{
          color:#fff;
          text-decoration:none;
          &:before{
            content:'・';
          }
        }
      }
    }
    .more{
      text-align:right;
      a{
        color:#fff;
        text-decoration:none;
      }
    }
  }
}

#shop_list{
  padding:40px 0;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding:vw(50) 0;
  }
  h2{
    @include base_h2(); 
  }
  h3{
    cursor:pointer;
    color:basecolor();
    border-bottom: 3px solid basecolor();
    font-size:rem(26);
    padding-bottom:10px;
    margin-bottom:30px;
    transition: .5s;
    svg{
      width:20px;
      fill:basecolor();
    }
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size:vw(36);
      padding:vw(36);
      margin-bottom:0;
      background:#000;
      border-bottom:3px solid #fff;
      color:#fff;
      svg{
        width:vw(22);
        fill:#fff;
      }
    }
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: center;
    &.on{
      @media only screen and (max-width:$SmartphoneSize + px){
        color:basecolor();
        background:#fff;
        border-bottom:3px solid basecolor();
      }
      svg{
        fill:#f9ac28;
        transform: rotate(90deg);
      }
    }
  }
  .list_wrap{
    display:none;
    ul{
      @media only screen and (max-width:$SmartphoneSize + px){
        margin-top:vw(36);
      }
      display: flex;
      justify-content: flex-start;
      flex-wrap:wrap;
      align-items: stretch;
      li{
        width:360px;
        margin-right: 20px;
        border: 1px solid #ccc;
        border-radius:5px;
        &:nth-child(3n){
          margin-right:0;
        }
        margin-bottom:20px;
        @media only screen and (max-width:$SmartphoneSize + px){
          width:100%;
          margin-right:0;
          margin-bottom:vw(32);
        }
        a{
          display:block;
          padding:10px;
          @media only screen and (max-width:$SmartphoneSize + px){
            padding:vw(36);
          }
          text-decoration:none;
          color:#000;
          .top{
            margin-bottom:10px;
            @media only screen and (max-width:$SmartphoneSize + px){
              margin-bottom:vw(36);
            }
            display: flex;
            justify-content: space-between;
            flex-wrap:wrap;
            align-items: center;
            img{
              width:90px;
              height:auto;
              @media only screen and (max-width:$SmartphoneSize + px){
                width:vw(160);
              }
            }
            p{
              font-size:rem(20);
              font-weight:bold;
              width:calc(100% - 100px);
              @media only screen and (max-width:$SmartphoneSize + px){
                font-size:vw(30);
                width:calc(100% - #{vw(196)});
              }
              span{
                display: flex;
                justify-content: flex-start;
                flex-wrap:wrap;
                align-items: center;
                &:before{
                  content:'';
                  margin-right:10px;
                  width:20px;
                  height:20px;
                  @media only screen and (max-width:$SmartphoneSize + px){
                    width:vw(36);
                    height:vw(36);
                    margin-right:vw(18);
                  }
                  background:url(../images/icon_tel.svg) no-repeat center;
                  background-size:cover;
                  display:block;
                }
              }
            }
          }
          dl{
            width:100%;
            div{
              display: flex;
              justify-content: space-between;
              flex-wrap:wrap;
              align-items: flex-start;
              margin-bottom:10px;
              &:last-child{
                margin-bottom:0;
              }
              dt{
                width:90px;
                text-align:center;
                @media only screen and (max-width:$SmartphoneSize + px){
                  width:vw(160);
                }
              }
              dd{
                width:calc(100% - 100px);
                @media only screen and (max-width:$SmartphoneSize + px){
                  width:calc(100% - #{vw(196)});
                }
              }
            }
          }
        }
      }
    }
  }
}

#bloc_list{
  padding:40px 0;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding:vw(50) 0;
  }
  h2{
    @include base_h2(); 
  }
  ul{
      margin-bottom:30px;
      display: flex;
      justify-content: flex-start;
      flex-wrap:wrap;
      align-items: stretch;
    li{
        width:360px;
        margin-right: 20px;
        border:1px solid #ccc;
        &:nth-child(3n){
          margin-right:0;
        }
        margin-bottom:20px;
        @media only screen and (max-width:$SmartphoneSize + px){
          width:100%;
          margin-right:0;
          margin-bottom:vw(32);
        }
      a{
        padding:20px;
        font-size:rem(20);
        @media only screen and (max-width:$SmartphoneSize + px){
          padding:vw(36);
          font-size:vw(30);
        }
        color:#000;
        border-radius:5px;
        text-decoration:none;
        display: flex;
        justify-content: space-between;
        flex-wrap:wrap;
        align-items: center;
        .shopname{
          display: flex;
          justify-content: center;
          flex-wrap:wrap;
          align-items: center;
          color:#fff;
          background:#f9ac28;
          font-size:rem(18);
          width:150px;
          height:150px;
          @media only screen and (max-width:$SmartphoneSize + px){
            font-size:vw(30);
            width:vw(303);
            height:vw(303);
          }
          p{
            text-align:center;
            font-weight:bold;
            span{
              display:block;
              font-size:rem(16);
              @media only screen and (max-width:$SmartphoneSize + px){
                font-size:vw(28);
              }
            }
          }
        }
        .eye{
          img{
            width:150px;
            height:150px;
            @media only screen and (max-width:$SmartphoneSize + px){
              width:vw(303);
              height:vw(303);
            }
            object-fit:cover;
          }
        }
        .title{
          width:100%;
          font-weight:bold;
          margin-top:20px;
        }
        .day{
          margin-top:10px;
          width:100%;
          text-align:right;
        }
      }
    }
  }

}

.breadcrumb{
  background:#ececec;
  @include bg();
  ul{
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    padding:5px 0;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding:vw(10) vw(18);
    }
    li{
      a,span{
        display:inline-block;
        color:#000;
        text-decoration:none;
        font-size:rem(14);
        @media only screen and (max-width:$SmartphoneSize + px){
          font-size:vw(20);
        }
      }
      a{
        &:after{
          content:'>';
          margin:0 10px;
          @media only screen and (max-width:$SmartphoneSize + px){
            margin:0 vw(10);
          }
        }
      }
    }
  }
}


.btn{
  width:100%;
  display:block;
  a{
    font-weight:bold;
    text-decoration:none;
    font-size:rem(20);
    padding:10px 15px;
    svg{
      width:18px;
      fill:#fff;
    }
    span{
      display:block;
      text-align:center;
      width:calc(100% - 18px);
    }
    border-radius:5px;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: center;
    background:#fe5f51;
    color:#fff;
  }
}

.contact_box{
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
  align-items: stretch;
  margin-bottom:40px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom:vw(50);
  }
  a{
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    width:365px;
    padding:15px 0;
    @media only screen and (max-width:$SmartphoneSize + px){
      width:100%;
      padding:vw(20) 0;
    }
    background:#0c45ae;
    border-radius:50px;
    &:first-child{
      margin-right:20px;
      @media only screen and (max-width:$SmartphoneSize + px){
        margin-right: 0;
        margin-bottom:vw(30);
        img{
          width:vw(430);
          height:auto;
        }
      }
    }
    @media only screen and (max-width:$SmartphoneSize + px){
      &:last-child{
        img{
          width:vw(456);
          height:auto;
        }
      }
    }
    &:first-child:before{
      display:block;
      content:'';
      width:43px;
      height:43px;
      margin-right:10px;
      @media only screen and (max-width:$SmartphoneSize + px){
        width:vw(88);
        height:vw(88);
        margin-right:vw(20);
      }
      background:url(../images/icon_tel_flow.svg) no-repeat center;
      background-size:cover;
    }
    &:last-child:before{
      display:block;
      content:'';
      width:34px;
      height:25px;
      margin-right:10px;
      @media only screen and (max-width:$SmartphoneSize + px){
        width:vw(88);
        height:vw(64);
        margin-right:vw(20);
      }
      background:url(../images/icon_mail.svg) no-repeat center;
      background-size:cover;
    }
  }
}

.notice_box{
  border:2px dotted #000;
  padding:40px;
  margin-bottom:40px;
  p{
    margin-bottom:20px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom:vw(30);
    }
    &:last-child{
      margin-bottom:0;
    }
  }
  @media only screen and (max-width:$SmartphoneSize + px){
    padding:vw(40);
    margin-bottom:vw(40);
  }
}

.sitemap{
  padding:30px 0;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding:vw(40) 0;
  }
  background:#0c45ae;
  @include bg();
  .inner{
    display: flex;
    flex-wrap:wrap;
    align-items: stretch;
    .box{
      width:33%;
      padding-right: 10px;
      @media only screen and (max-width:$SmartphoneSize + px){
        width:100%;
        margin-right:0;
      }
      .title{
        color:#fff;
        font-weight:bold;
        border-bottom:1px solid #fff;
        margin-bottom: 5px;
      }
      ul{
        @media only screen and (max-width:$SmartphoneSize + px){
          margin-bottom: vw(30);
        }
        li{
          a,span{
            color:#fff;
            text-decoration:none;
            cursor:pointer;
            display:block;
            @media only screen and (max-width:$SmartphoneSize + px){
              padding:vw(10) 0;
            }
            &:before{
              content:'» ';
            }
          }
          &.child{
            ul{
              display:none;
              padding-left:10px;
              width:100%;
              padding:0;
            }
          }
        }
      }
    }
  }
}

#post{
  .h1_wrap{
    background:#000;
    @include bg();
    padding:30px 0;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding:vw(50) 0;
    }
    h1{
      font-size:rem(34);
      color:#ffff00;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size:vw(40);
      }
    }
  }
  #post_content{
    .inner{
      display: flex;
      justify-content: space-between;
      flex-wrap:wrap;
      align-items: flex-start;
      padding:40px 0;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding:vw(50) 0;
      }
      .left_column{
        width:800px;
        @media only screen and (max-width:$SmartphoneSize + px){
          width:100%;
          padding:0 vw(18);
        }
        .post_meta{
          display: flex;
          flex-wrap:wrap;
          align-items: stretch;
          p{
            font-size:rem(14);
            padding:5px 0;
            margin-bottom:40px;
            @media only screen and (max-width:$SmartphoneSize + px){
              font-size:vw(24);
              padding:vw(20) 0;
              margin-bottom:vw(40);
            }
            border: 1px solid #f9ac28;
            display: flex;
            justify-content: center;
            flex-wrap:wrap;
            align-items: center;
            &:first-child{
              width:240px;
              img{
                margin-right: 20px;
              }
              @media only screen and (max-width:$SmartphoneSize + px){
                width:100%;
                img{
                  margin-right:vw(30);
                  width:vw(29);
                  height:vw(31);
                }
              }
            }
            &:last-child{
              width:calc(100% - 240px);
              @media only screen and (max-width:$SmartphoneSize + px){
                width:100%;
              }
              background:#f9ac28;
              color:#fff;
              font-weight:bold;
            }
          }
        }

        article{
          h2{
            font-size:rem(30);
            padding:15px;
            margin-bottom:40px;
            line-height: 1.2;
            @media only screen and (max-width:$SmartphoneSize + px){
              font-size:vw(36);
              padding:vw(18);
              margin-bottom:vw(40);
            }
            &:before{
              content:'';
              width:50px;
              height:39px;
              margin-right:20px;
              @media only screen and (max-width:$SmartphoneSize + px){
                width: vw(65);
                height:vw(39);
                margin-right:vw(20);
              }
              background:url(../images/icon_h2_post.svg) no-repeat left top;
              background-size: cover;
            }
            color:#fff;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            background:basecolor();
          }

          h3{
            font-size:rem(26);
            margin-bottom:30px;
            padding-bottom:10px;
            @media only screen and (max-width:$SmartphoneSize + px){
              font-size:vw(36);
              margin-bottom:vw(40);
              padding-bottom:vw(20);
            }

            border-bottom:3px solid basecolor();
            color:basecolor();
          }

          h4{
            font-size:rem(22);
            margin-bottom:30px;
            padding-bottom:10px;
            @media only screen and (max-width:$SmartphoneSize + px){
              font-size:rem(32);
              margin-bottom:vw(40);
              padding-bottom:vw(20);
            }
            border-bottom:1px dotted #000;
          }

          p{
            margin-bottom:40px;
          }

          a{
            color:#fe5f51;
          }

          img{
            max-width: 100%;
            height:auto;
          }

        }



      }
      .right_column{
        width:260px;
        .tit{
          display: flex;
          flex-wrap:wrap;
          align-items: center;
          @include gfont();
          font-size:rem(26);
          margin-bottom:10px;
          padding-bottom:20px;
          @media only screen and (max-width:$SmartphoneSize + px){
            font-size:vw(36);
            margin-bottom:vw(20);
            padding-bottom:vw(30);
          }
          img{
            margin-right:10px;
            @media only screen and (max-width:$SmartphoneSize + px){
              width:vw(51);
              height:vw(48);
              margin-right: vw(20);
            }
          }
          border-bottom:3px solid basecolor();
        }
        .eye{
          text-align:center;
          margin-bottom:10px;
          @media only screen and (max-width:$SmartphoneSize + px){
            margin-bottom: vw(20);
          }
          img{
            height:100px;
            width:auto;
            @media only screen and (max-width:$SmartphoneSize + px){
              height:vw(240);
            }
          }
        }
        dl{
          div{
            margin-bottom:20px;
            @media only screen and (max-width:$SmartphoneSize + px){
              margin-bottom:vw(30);
            }
            dt{
              padding:10px 0;
              cursor:pointer;
              @media only screen and (max-width:$SmartphoneSize + px){
                padding:vw(20) 0;
              }
              color:#fff;
              text-align:center;
              width:100%;
              border-radius:50px;
              background:#000;
              position:relative;
              font-weight:bold;
              svg{
                position:absolute;
                width:13px;
                height:14px;
                right:20px;
                @media only screen and (max-width:$SmartphoneSize + px){
                  width:vw(22);
                  height:vw(24);
                  right:vw(20);
                }
                margin:auto;
                top: 0;
                bottom:0;
                fill:#fff;
              }
              &.on{
                color: #000;
                background:#f9ac28;
                svg{
                  transform: rotate(90deg);
                  fill:#000;
                }
              }
            }
            dd{
              display:none;
              ul{
                li{
                  a{
                    &:before{
                      content:'» ';
                    }
                    text-decoration:none;
                    display:block;
                    font-weight:bold;
                    color:#000;
                    padding:15px 0;
                    @media only screen and (max-width:$SmartphoneSize + px){
                      padding:vw(30) 0;
                    }
                    border-bottom: 1px dotted #000;
                  }
                }
              }
            }
          }
        }


        @media only screen and (max-width:$SmartphoneSize + px){
          width:100%;
          padding:vw(60) vw(18);
        }
      }
    }
  }
}


/*pagenavi*/

.wp-pagenavi
{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  flex-wrap:wrap;
  -webkit-align-items: center;
  align-items: center;
  padding:20px 0;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding:vw(20) 0;
  }
}

.wp-pagenavi span,
.wp-pagenavi a
{
  border:1px solid basecolor();
  display: inline;
  padding:10px 17px;
  display: block;
  margin:0 10px;
  text-decoration:none;
}

.wp-pagenavi span
{
  background: basecolor();
  color: #fff;
}

.wp-pagenavi a
{
  color: basecolor();
}

@media only screen and (max-width:750px){
.wp-pagenavi span,
.wp-pagenavi a
{
  padding:2.4vw 3vw;
  margin:0 vw(20);
}
}

#performance{
  .wp-pagenavi span,.wp-pagenavi a{
    color:#fff;
    border:1px solid #fff;
  }
  .wp-pagenavi span
  {
    background: #fff;
    color: #000;
  }
}

#form{
  width:740px;
  @media only screen and (max-width:$SmartphoneSize + px){
    width:100%;
  }
  margin:0 auto;
  dl{
      margin-bottom:30px;
      @media only screen and (max-width:$SmartphoneSize + px){
        margin-bottom:vw(30);
      }
    div{
      display: flex;
      flex-wrap:wrap;
      border-bottom:1px solid #ccc;
      dt{
        display: flex;
        flex-wrap:wrap;
        align-items: center;
        width:240px;
        padding:10px;
        font-size:rem(20);
        background:#ececec;
        @media only screen and (max-width:$SmartphoneSize + px){
          width:100%;
          text-align:center;
          padding:vw(15);
          font-size:vw(30);
        }
        span{
          margin-left:5px;
          font-size:70%;
          background:#fe5f51;
          color:#fff;
          border-radius:30px;
          padding:0 10px;
          &:before{
            content:'必須';
          }
        }
      }
      dd{
        width:calc(100% - 240px);
        padding:10px;
        font-size:rem(20);
        span{
          font-size:rem(14);
        }
        input[type="text"],input[type="email"],textarea{
          width:100%;
          padding:5px;
          font-size:110%;
        }
        @media only screen and (max-width:$SmartphoneSize + px){
          width:100%;
          padding:vw(30);
          font-size:vw(30);
          text-align:center;
          span{
            font-size:vw(24);
          }
        }
      }
    }
  }
  .send{
    text-align:center;
    input{
      font-size:130%;
      padding:10px 60px;
      border-radius:30px;
      background:#fe5f51;
      color:#fff;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding:vw(20);
        width:100%;
      }
    }
  }
}

footer{
  padding:20px 0;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding:vw(30) 0;
  }
  @include bg();
  background:#000;
  text-align:center;
  color:#fff;
  font-size:60%;
}
